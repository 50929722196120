import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi.
    </Text>
    <p>{`It may seem a little silly that I'm choosing to write letters to you out in the open. It is a little silly, though I don't feel like it's entirely in the open if only you and I know about it. For some reason I felt like creating another site would give me the motivation I need to write you the letters that deserve to be written. Who knows if it will work, but I feel like I need to start somewhere. `}</p>
    <p><a parentName="p" {...{
        "href": "/merry-christmas-dani"
      }}>{`Merry Christmas Dani`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      